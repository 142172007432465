import { action, observable, toJS } from 'mobx';
import type CommunityModel from '../../model';
import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { ChangeEvent } from 'react';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import { uniqueId } from 'lodash';

export default class AddModel {
  constructor(parentStore: CommunityModel) {
    this.parentStore = parentStore;
    // this.getHuaWeiObsConfig();
    this.getPriceCategory();
    this.getCornerMarker();
  }

  @observable public visible = false;

  @observable public loading = false;

  @observable public unitOptions = [];

  @observable public cornerMarkers = []; // 群角标

  @observable public activeLabelList: string[] = []; // 选中的群角标

  @observable public huaWeiObsConfig: any = {};

  @observable public detailList = [];

  @observable public anchorList = [];

  @observable public choosedAnchorList = [];

  @observable public total = 0;

  @observable public page = 1;

  @observable public pageSize = 30;

  @observable public searchKey = '';

  @observable public type = 0;

  public priceId: string;

  @action
  public setType = (type) => {
    if (type === this.type) {
      return;
    }
    this.type = type;
    this.searchKey = undefined;
    this.anchorList = [];
    this.choosedAnchorList = [];
    this.total = 0;
    this.queryAnchor();
  };

  public getPriceCategoryName = (id): string => {
    let name = '';
    this.unitOptions.forEach((item) => {
      if (item.dictValue === id) {
        name = item.dictName;
      }
    });
    return name;
  };

  @action
  public clearPriceTemplate = () => {
    this.priceId = undefined;
    this.detailList = [];
  };

  @action
  public onSubmit = () => {
    this.formRef.current.validateFields().then(async (values) => {
      if (!this.priceId) {
        return;
      }
      const params = {
        ...values,
        priceTemplateId: this.priceId,
      };
      if (params.cityId) {
        const [provinceId, cityId] = params.cityId;
        params.provinceId = provinceId;
        params.cityId = cityId;
      }
      if (params.groupChatList) {
        params.groupChatList = params.groupChatList.map((item) => item.url);
      }
      if (this.choosedAnchorList.length) {
        params.anchorList = this.choosedAnchorList.map((el, index) => ({
          ...el,
          sort: index + 1,
        }));
      }
      await request({
        method: 'POST',
        url: this.editId ? '/quan/biz_social_group/manage/group/update' : '/quan/biz_social_group/manage/group/add',
        data: {
          ...params,
          id: this.editId,
        },
      });
      message.success('保存成功');
      this.setVisible();
      this.parentStore.mainSubStructureModel.onQuery();
    });
  };

  @action
  public queryAnchor = async () => {
    let url = '/quan/biz/anchor/list/page';
    let params: any = { name: this.searchKey };
    if (this.type === 1) {
      url = '/quan/biz/expert/list/page';
      params = { nickName: this.searchKey };
    }
    const req = await request<any>({
      method: 'POST',
      url,
      data: {
        pageNum: this.page,
        pageSize: this.pageSize,
        ...params,
      },
    });
    let _anchorList = [];
    if (this.type === 0) {
      _anchorList = (req?.data?.list || [])?.map((item) => ({
        anchorId: item.id,
        anchorNick: item.name,
        checked: false,
        worksNum: item.worksNum,
      }));
    }
    if (this.type === 1) {
      _anchorList = (req?.data?.list || [])?.map((item) => ({
        anchorId: item.id,
        anchorNick: item.nickName,
        checked: false,
        worksNum: item.worksNum,
      }));
    }

    this.total = req.data.total;
    this.anchorList = _anchorList;
    this.handleWriteAnchor();
  };

  @action
  public onPageChange = (page: number) => {
    this.page = page;
    this.queryAnchor();
  };

  @action
  public onSeachChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.searchKey = e.target.value;
  };

  @action
  public onAnchorListChange = (checked: boolean, id: string) => {
    for (let i = 0; i < this.anchorList.length; i++) {
      const element = this.anchorList[i];
      if (element.anchorId === id) {
        element.checked = checked;
      }
    }
  };

  @action
  public choosedAnchorChange = (checked: boolean, id: string) => {
    // for (let i = 0; i < this.choosedAnchorList.length; i++) {
    //   const element = this.choosedAnchorList[i];
    //   if (element.anchorId === id) {
    //     element.checked = checked;
    //   }
    // }
    this.choosedAnchorList = toJS(this.choosedAnchorList).map((item) => {
      if (item.anchorId === id) {
        item.checked = checked;
      }
      return item;
    });
  };

  @action
  public addAnchor = () => {
    const _choosed = [];
    for (let i = 0; i < this.anchorList.length; i++) {
      const element = this.anchorList[i];
      if (element.checked) {
        const index = this.choosedAnchorList.findIndex((el) => el.anchorId === element.anchorId);
        if (index === -1) {
          _choosed.push({
            ...element,
            checked: false,
          });
        }
      }
    }
    this.choosedAnchorList = [...this.choosedAnchorList, ..._choosed];
  };

  @action
  public deleteAnchor = () => {
    const _choosed = [];
    for (let i = this.choosedAnchorList.length - 1; i >= 0; i--) {
      const element = this.choosedAnchorList[i];
      if (element.checked) {
        this.choosedAnchorList.splice(i, 1);
        const index = this.anchorList.findIndex((el) => el.anchorId === element.anchorId);
        if (index !== -1) {
          this.anchorList[index].checked = false;
        }
      }
    }
  };

  @action
  public getPriceCategory = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType: 'price_category' },
    });
    this.unitOptions = req.data;
  };

  // 查询群角标
  @action
  public getCornerMarker = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType: 'index_sort_type' },
    });
    this.cornerMarkers =
      req.data?.map((item) => ({
        label: item.dictName,
        value: item.dictValue,
        checked: false,
      })) || [];
  };

  @action
  public getHuaWeiObsConfig = async () => {
    const req = await request<{ data: any }>({
      method: 'GET',
      url: '/file/obs/getHuaWeiObsConfig',
    });
    this.huaWeiObsConfig = req.data || {};
  };

  public formRef = React.createRef<FormInstance>();

  @action
  public setVisible = () => {
    this.visible = !this.visible;
    if (!this.visible) {
      this.editId = undefined;
      this.priceId = undefined;
      this.type = 0;
      this.detailList = [];
      this.formRef.current.resetFields();
      this.choosedAnchorList = [];
      this.searchKey = '';
      this.total = 0;
      this.anchorList = [];
      this.activeLabelList = [];
      this.cornerMarkers.forEach((item) => (item.checked = false));
    } else {
      this.queryAnchor();
    }
  };

  @action
  public setLoading = () => {
    this.loading = !this.loading;
  };

  /**
   * 关闭
   */
  @action
  public onCancel = () => {
    this.setVisible();
  };

  @observable public editId: string;

  @action
  public onEdit = async (id: string) => {
    const req = await request<any>({
      method: 'POST',
      url: '/quan/biz_social_group/manage/group/detail',
      data: { id },
    });
    this.setVisible();
    const { cityId, provinceId, groupType, priceTemplateId, groupCode, groupChatList, firstCategoryId, secondCategoryId, managerId, groupAvatar, groupName, sort, groupDesc, isTemplate, anchorCount, anchorList, sharePic, labelIds } =
      req?.data || {};
    this.editId = id;
    this.priceId = priceTemplateId;
    const reqDetail = await request<BaseData<{ detailList: [] }>>({
      method: 'GET',
      url: '/quan/biz/price/template/detail',
      params: { id: priceTemplateId },
    });
    this.detailList = reqDetail?.data?.detailList || [];
    this.setType(groupType);
    this.parentStore.getCategorySecond(firstCategoryId);
    this.activeLabelList = labelIds || [];
    this.activeLabelList.forEach((item: any) => {
      this.cornerMarkers.forEach((el) => {
        if (el.value === item) {
          el.checked = true;
        }
      });
    });
    this.formRef.current.setFieldsValue({
      cityId: [provinceId, cityId],
      firstCategoryId,
      secondCategoryId,
      managerId,
      groupAvatar,
      groupName,
      groupDesc,
      groupCode,
      isTemplate,
      sort,
      groupType,
      anchorCount,
      groupChatList: groupChatList.map((item) => ({
        uid: uniqueId(),
        status: 'done',
        url: item,
      })),
      sharePic,
      labelIds,
    });
    this.choosedAnchorList = anchorList.map((el) => ({
      ...el,
      checked: false,
    }));
    this.handleWriteAnchor();
  };

  @action
  public handleWriteAnchor = () => {
    if (this.anchorList.length) {
      for (let i = 0; i < this.choosedAnchorList.length; i++) {
        const element = this.choosedAnchorList[i];
        const index = this.anchorList.findIndex((el) => el.anchorId === element.anchorId);
        if (index !== -1) {
          this.anchorList[index].checked = true;
        }
      }
    }
  };

  @action
  public moveItem = (arr, fromIndex, toIndex) => {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
      throw new Error('Index out of bounds');
    }
    const newArr = [...arr];
    const [movedItem] = newArr.splice(fromIndex, 1); // 从 fromIndex 移除元素，并获取该元素
    newArr.splice(toIndex, 0, movedItem);
    return newArr;
  };

  @action
  public onSortEnd = ({ oldIndex, newIndex }) => {
    this.choosedAnchorList = this.moveItem(this.choosedAnchorList, oldIndex, newIndex);
  };

  public parentStore: CommunityModel;
}
