import React from 'react';
import { observer } from 'mobx-react';
import styles from './index.less';
import { Checkbox, Input, Pagination } from 'antd';
import type AddModel from './model';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { UnorderedListOutlined } from '@ant-design/icons';

@observer
export default class AddAnchor extends React.Component<{ store: AddModel }> {
  render() {
    const { anchorList, choosedAnchorList, total, pageSize, onPageChange, searchKey, onSeachChange, queryAnchor, onAnchorListChange, addAnchor, deleteAnchor, choosedAnchorChange, onSortEnd } = this.props.store;
    const DragHandle = SortableHandle(() => <UnorderedListOutlined style={{ fontSize: '16px' }} />);

    const SortableItem = SortableElement(({ item }) => (
      <div className={styles.itemWrap}>
        <Checkbox
          checked={item.checked}
          onChange={(e: CheckboxChangeEvent) => choosedAnchorChange(e.target.checked, item.anchorId)}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.ml8}>{item.anchorNick}</div>
          <div style={{ display: 'flex' }}>
            <div
              className={styles.ml8}
              style={{ marginRight: '8px' }}
            >
              {item.worksNum}作品
            </div>
            <DragHandle />
          </div>
        </div>
      </div>
    ));

    const SortableWrap = SortableContainer(() => (
      <div>
        {choosedAnchorList?.map((el, index) => (
          <SortableItem
            key={el.anchorId}
            // @ts-ignore
            item={el}
            index={index}
          />
        ))}
      </div>
    ));
    return (
      <div className={styles.addAnchorWrap}>
        <div className={styles.dataWrap}>
          <Input
            placeholder="搜索"
            value={searchKey}
            onChange={onSeachChange}
            onPressEnter={queryAnchor}
          />
          <div
            style={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            {anchorList?.map((el) => (
              <div
                key={el.anchorId}
                className={styles.itemWrap}
              >
                <Checkbox
                  checked={el.checked}
                  onChange={(e: CheckboxChangeEvent) => onAnchorListChange(e.target.checked, el.anchorId)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div className={styles.ml8}>{el.anchorNick}</div>
                  <div className={styles.ml8}>{el.worksNum}作品</div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ padding: '4px 0' }}>
            <Pagination
              pageSize={pageSize}
              total={total}
              onChange={onPageChange}
              size="small"
            />
          </div>
        </div>
        <div>
          <div
            className={styles.addBtn}
            onClick={addAnchor}
          >
            添加
          </div>
          <div
            className={styles.addBtn}
            onClick={deleteAnchor}
            style={{ marginTop: '4px' }}
          >
            删除
          </div>
        </div>
        <div className={styles.dataWrap}>
          <SortableWrap
            onSortEnd={onSortEnd}
            useDragHandle
          />
        </div>
      </div>
    );
  }
}
