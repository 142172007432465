import React from 'react';
import { Modal, Upload } from 'antd';
import { observer } from 'mobx-react';
import type Model from './model';

// 导入群成员
export default observer(({ store }: { store: Model }): React.ReactElement => {
  if (!store) {
    return null;
  }
  const { visible, saveLoading, errorInfo, onClose, onSave, beforeUpload } = store;

  return (
    <Modal
      open={visible}
      maskClosable={false}
      centered
      onCancel={onClose}
      title="导入群成员"
      footer={null}
    >
      <Upload
        accept=".xlsx,.xls"
        beforeUpload={beforeUpload}
        listType="picture-card"
        showUploadList={false}
        disabled={saveLoading}
        customRequest={(i: any) => {
          const fd = new FormData();
          fd.append('file', i.file);
          onSave(fd);
        }}
      >
        <button
          type="button"
          style={{
            border: 0,
            background: 'none',
          }}
        >
          <div className="mt-[8px]">{saveLoading ? '正在导入,请勿关闭窗口...' : '上传Excel'}</div>
        </button>
      </Upload>
      <div className="mt-[8px]">仅支持上传Excel文件</div>
      {errorInfo?.list?.length > 0 && (
        <div className="flex flex-col mt-[8px]">
          <div className="text-[16px] text-[red] flex flex-col">
            <div className="text-[#87d068]">
              <span className="text-[#87d068]">成功{Number(errorInfo.total - errorInfo.failCount) < 0 ? 0 : Number(errorInfo.total - errorInfo.failCount)}条 </span>
              <span className="text-[red]">失败{errorInfo.failCount}条</span>
            </div>
            <span className="text-[#999]">错误信息：</span>
          </div>
          <div className="w-full max-h-[350px] mt-[8px] overflow-auto customScrollBar">
            {errorInfo?.list?.map((error, i) => (
              <div
                className="text-[#999] pl-[8px]"
                key={`error-${i.toString()}`}
              >
                {i + 1}. {error}
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
});
