import React from 'react';
import CommunityModel from './model';
import { MainSubStructure, NormalProgrammeComponent, RenderByPermission } from '../../utils';
import styles from './index.less';
import { Button, Card, Empty, Form, Input, InputNumber, Modal, Radio, Select, Space } from 'antd';
import Detail from './compents/detail';
import Add from './compents/add';
import Import from './compents/import';
import { observer } from 'mobx-react';

@observer
export default class CommunityPage extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new CommunityModel();
  }

  public declare store: CommunityModel;

  render() {
    const {
      normalProgramme,
      setPriceTemplateOpen,
      priceTemplateId,
      setPriceTemplateId,
      priceTemplateOpen,
      priceTemplateList,
      setPriceOpen,
      priceOpen,
      onSave,
      setPrice,
      setPriceData,
      priceData,
      onPriceTemplateSave,
      addModel,
      importModel,
      mainSubStructureModel,
      detailModel,
    } = this.store;
    const pageId = new URLSearchParams(window.location.search)?.get('pageId');

    return (
      <div className={styles.page}>
        <Detail store={detailModel} />
        <Add store={addModel} />
        <Import store={importModel} />
        <NormalProgrammeComponent store={normalProgramme} />
        <div className={styles.buttons}>
          <span>总量 {mainSubStructureModel?.gridModel?.total}</span>
          <Space>
            <RenderByPermission permissionId={`${pageId}_22`}>
              <Button
                onClick={() => {
                  console.log(window.top.RingPermission);
                  window.top.RingPermission.openTab('/pbb-pc-anchorcircle/priceTemplate/index', 160005, '价格模版');
                }}
              >
                价格模版管理
              </Button>
            </RenderByPermission>
            <RenderByPermission permissionId={`${pageId}_2`}>
              <Button
                onClick={() => {
                  addModel.setVisible();
                }}
              >
                新增
              </Button>
            </RenderByPermission>
          </Space>
        </div>
        <div className={styles.mainSubStructure}>
          <MainSubStructure store={mainSubStructureModel} />
        </div>
        <Modal
          title="导入价格模版"
          open={priceTemplateOpen}
          width="70%"
          onCancel={setPriceTemplateOpen}
          className={styles.modalCard}
          onOk={() => {
            onPriceTemplateSave();
          }}
        >
          {!priceTemplateList.length ? (
            <div className={styles.empty}>
              <Empty />
            </div>
          ) : undefined}
          {priceTemplateList.length ? (
            <Space className={styles.spaceCard}>
              {priceTemplateList.map((item) => {
                const a = 1;
                return (
                  <Card
                    className={styles.card}
                    key={item.id}
                  >
                    <div>
                      <span title={`${item.regionName} > ${item.parentCategoryName} > ${item.categoryName}`}>
                        {item.regionName} {' > '} {item.parentCategoryName} {'>'} {item.categoryName}
                      </span>
                      <Radio
                        checked={item.id === priceTemplateId}
                        onChange={() => {
                          setPriceTemplateId(item.id);
                        }}
                      />
                    </div>
                    {item.detailList.map((_item) => (
                      <Space
                        className={styles.detailListItem}
                        key={_item.id}
                      >
                        <span className={styles.duration}>{_item.duration}</span>
                        {addModel?.getPriceCategoryName(_item.priceCategory)}
                        <span className={styles.price}>{_item.price}元</span>
                      </Space>
                    ))}
                  </Card>
                );
              })}
            </Space>
          ) : undefined}
        </Modal>
        <Modal
          open={priceOpen}
          onCancel={() => {
            setPriceOpen();
          }}
          width={800}
          onOk={() => {
            onSave();
          }}
          title="设置价格"
        >
          <div>
            {priceData.map((_: any, i) => (
              <Space
                key={`${i.toString()}id`}
                className={styles.row}
              >
                标题
                <Input
                  onChange={(e) => {
                    setPrice({ title: e.target.value }, i);
                  }}
                  style={{ width: 100 }}
                  maxLength={8}
                  value={_.title}
                  autoComplete="off"
                />
                会员时长
                <InputNumber
                  min={0}
                  onChange={(value) => {
                    setPrice({ duration: value }, i);
                  }}
                  value={_.duration}
                  precision={0}
                  max={100}
                />
                <Select
                  onChange={(value) => {
                    setPrice({ priceCategory: value }, i);
                  }}
                  value={_.priceCategory}
                  fieldNames={{
                    label: 'dictName',
                    value: 'dictValue',
                  }}
                  options={addModel.unitOptions}
                  className={styles.unit}
                />{' '}
                <span>价格</span>
                <InputNumber
                  min={0}
                  precision={2}
                  max={9999}
                  value={_.price}
                  onChange={(value) => {
                    setPrice({ price: value }, i);
                  }}
                />
                元{/*{priceData.length < 3 ? (*/}
                {/*  <a*/}
                {/*    onClick={() => {*/}
                {/*      setPriceData(0);*/}
                {/*    }}*/}
                {/*    className={styles.add}*/}
                {/*  >*/}
                {/*    增加*/}
                {/*  </a>*/}
                {/*) : null}*/}
                {/*{i !== 0 ? (*/}
                {/*  <a*/}
                {/*    onClick={() => {*/}
                {/*      setPriceData(1, i);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    删除*/}
                {/*  </a>*/}
                {/*) : (*/}
                {/*  ''*/}
                {/*)}*/}
              </Space>
            ))}
          </div>
        </Modal>
      </div>
    );
  }
}
